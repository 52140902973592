.na-action-buttons {
    display: flex;
    flex-direction: row; /* Aligns the buttons in a row */
    align-items: flex-end; /* Aligns buttons vertically centered within the row */
    gap: 12px;
    right: 20px; /* Adjust this as needed for spacing from the right edge */
    top: 50%; /* Adjust based on desired position */
    transform: translateY(-50%), translateX(-100%); /* Centers vertically */
}

.export-btn,
.re-audit-btn {
    padding: 12px 25px;
    cursor: pointer;
    background-color: var(--accent-color);
    border: none;
    color: white;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s ease, transform 0.2s ease;
    margin: 0; /* Removes unnecessary margin if using absolute positioning */
}

.export-btn:hover,
.re-audit-btn:hover {
    background-color: #5fa9a2;
    transform: translateY(-2px);
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .tabs {
        flex-direction: column;
        align-items: center;
    }

    .tab {
        margin-bottom: 10px;
        width: 90%;
        text-align: center;
    }

    .na-action-buttons {
        width: 100%; /* Ensures it takes full width on small screens */
        position: static; /* Reverts to normal flow on smaller screens */
        align-items: center; /* Centers buttons on small screens */
        flex-direction: column; /* Stacks buttons in a column on small screens */
    }

    .export-btn,
    .re-audit-btn {
        width: 80%;
        margin: 10px 0; /* Adds spacing between buttons */
    }
}

/* Page container for consistent alignment */
.needs-audit-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    position: relative; /* Ensures absolute positioning works within the container */
}
