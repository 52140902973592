/* Tabs styling */
.tabs {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid var(--text-color);
    margin: 25px 0;
}

.tab {
    padding: 10px 20px;
    cursor: pointer;
    background-color: var(--background-color);
    border: 1px solid var(--text-color);
    border-bottom: none;
    border-radius: 5px 5px 0 0;
    margin-right: 10px;
    transition: background-color 0.3s ease, font-weight 0.3s ease;
}

.tab.active {
    background-color: var(--section-background);
    border-bottom: 2px solid var(--text-color);
    font-weight: bold;
}

.tab:hover {
    background-color: #e0e0e0;
}

.tab-content {
    margin-top: 20px;
}
