.ap-report-export {
    margin: 20px 0;
    text-align: center;
}

.export-button {
    background-color: #ff9800;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.export-button:hover {
    background-color: #e68900;
}
