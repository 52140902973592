.totals-container {
    margin-top: 20px;
    padding: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center; /* Center the totals cards horizontally */
    gap: 15px;
}

.total-field {
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 4px;
    background-color: var(--background-color);
    box-shadow: 0 0 5px var(--shadow-color);
    min-width: 150px;
    text-align: center;
}

.total-label {
    font-weight: bold;
    margin-bottom: 5px;
    color: var(--text-color);
}

.total-value {
    color: var(--accent-color);
    font-size: 1.2rem;
    font-weight: bold;
}
