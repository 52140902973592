.agents-list {
  padding: 20px;
  background-color: var(--section-background);
  color: var(--text-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-color);
}

.agents-list h2 {
  margin-bottom: 20px;
}

.add-agent-button, .upload-agent-button {
  background-color: var(--accent-color);
  color: var(--text-color);
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 20px;
}



.add-agent-button,
.upload-agent-button {
  background-color: var(--accent-color);
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%; /* Full-width buttons */
}

.add-agent-button:hover,
.upload-agent-button:hover {
  background-color: var(--accent-color2);
}


.add-agent-button:hover, .upload-agent-button:hover {
  background-color: var(--accent-color2);
}

.agents-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--section-background);
}

.agents-table th, .agents-table td {
  padding: 10px;
  border: 1px solid var(--separator-color);
  text-align: left;
}

.agents-table th {
  background-color: var(--header-background);
  color: var(--text-color);
}

.agents-table td {
  background-color: var(--background-color);
}

.agents-table th.actions-column {
  width: 120px; /* Adjust this width as necessary */
  text-align: center;
}

.agents-table td.actions-column {
  text-align: center;
  white-space: nowrap; /* Prevent wrapping inside the actions column */
}

.btn-view, .btn-delete {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  color: var(--accent-color);
  margin: 0 5px; /* Add some space between buttons */
}

.btn-view:hover, .btn-delete:hover {
  color: var(--accent-color2);
}

.btn-delete {
  color: red;
}

/* Pagination Controls */
.pagination-controls {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}

.pagination-btn {
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
  font-size: 1rem;
}

.pagination-btn:disabled {
  background-color: var(--disabled-color);
  cursor: not-allowed;
}

.pagination-btn:hover:not(:disabled) {
  background-color: var(--accent-color2);
}

.pagination-controls span {
  font-size: 1.1rem;
  color: var(--text-color);
}

/* Override default react-confirm-alert styles to match the theme */
.react-confirm-alert-overlay {
  background: rgba(0, 0, 0, 0.7); /* Darker background overlay */
}

.react-confirm-alert {
  color: var(--text-color);
  border-radius: 8px;
  box-shadow: 0 4px 8px var(--shadow-color);
  padding: 20px;
  font-size: 1rem;
  max-width: 90%;
}

.react-confirm-alert .react-confirm-alert-body {
  text-align: center;
  color: var(--text-color);
  background-color: var(--section-background);
}

.react-confirm-alert .react-confirm-alert-button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
}

.react-confirm-alert-button-group button {
  background-color: var(--accent-color);
  color: var(--text-color);
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1rem;
}

.react-confirm-alert-button-group button:hover {
  background-color: var(--accent-color2);
}

.react-confirm-alert-button-group button:first-child {
  background-color: red;
  color: white;
}

.react-confirm-alert-button-group button:first-child:hover {
  background-color: darkred;
}

/* Table styles */
.agents-table {
  width: 100%;
  border-collapse: collapse;
  background-color: var(--background-color);
  color: var(--text-color);
}

.agents-table th,
.agents-table td {
  padding: 12px;
  border: 1px solid var(--separator-color);
  text-align: left;
}

.agents-table th {
  background-color: var(--section-background);
  text-transform: uppercase;
  font-weight: bold;
}

.agents-table td {
  background-color: var(--background-color);
}

.actions-column {
  text-align: center;
}

.actions-column button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.2rem;
  margin-right: 10px;
}

.actions-column .btn-view {
  color: var(--accent-color);
}

.actions-column .btn-delete {
  color: red;
}

.actions-column .btn-view:hover,
.actions-column .btn-delete:hover {
  opacity: 0.8;
}

/* Hover row effect */
.agents-table tr:hover {
  background-color: var(--hover-background-color);
}

/* Add agent and upload agent buttons */
.add-agent-button,
.upload-agent-button {
  background-color: var(--accent-color);
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.add-agent-button:hover,
.upload-agent-button:hover {
  background-color: var(--accent-color2);
}


/* Filters and actions container */
.filters {
  display: flex;
  justify-content: space-between; /* Space between filters and buttons */
  align-items: center; /* Align vertically in the center */
  margin-bottom: 20px;
  gap: 10px;
}

.search-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--separator-color);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  width: 250px; /* Adjust width as needed */
}

.filter-select {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--separator-color);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 1rem;
  max-width: 150px;
}

.actions-container {
  display: flex;
  gap: 10px;
  margin-left: auto; /* Pushes buttons to the right */
}

.add-agent-button,
.upload-agent-button {
  background-color: var(--accent-color);
  padding: 10px 20px;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

.add-agent-button:hover,
.upload-agent-button:hover {
  background-color: var(--accent-color2);
}

