 /* Pagination container */
 .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
    gap: 5px; /* Spacing between buttons */
  }
  
  /* Pagination buttons */
  .pagination-btn {
    background-color: var(--background-color);
    border: 1px solid var(--separator-color);
    color: var(--text-color);
    padding: 8px 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  /* Active page button */
  .pagination-btn.active {
    background-color: var(--accent-color);
    color: white;
    border-color: var(--accent-color);
  }
  
  /* Hover effect for pagination buttons */
  .pagination-btn:hover {
    background-color: var(--accent-color2);
    color: white;
  }
  