.add-agent-container {
  max-width: 600px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--section-background);
  color: var(--text-color);
  box-shadow: 0 4px 8px var(--shadow-color);
  border-radius: 8px;
}

.add-agent-container h2, .add-agent-container h3, .add-agent-container h4 {
  text-align: center;
  margin-bottom: 20px;
  color: var(--text-color);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: var(--text-color);
}

.form-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid var(--separator-color);
  border-radius: 4px;
  background-color: var(--background-color);
  color: var(--text-color);
  box-sizing: border-box;
}

button {
  padding: 10px;
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 1rem;
}

button:hover {
  background-color: var(--accent-color2);
}

.add-merchant-button {
  margin-bottom: 20px;
}

.merchant-item {
  margin-bottom: 10px;
  padding: 10px;
  background-color: var(--background-color);
  border-radius: 4px;
  box-shadow: 0 2px 4px var(--shadow-color);
}

.remove-merchant-button {
  background-color: red;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 10px;
}

.remove-merchant-button:hover {
  background-color: darkred;
}

.submit-button {
  background-color: var(--accent-color);
  color: var(--section-background);
}
